<template>
  <div class="row">

    <div class="col-md-12">
      <form @submit.prevent="onHitBottom(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-5 col-md-5 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    :model.sync="filters.search"
                    :placeholder="$t('general.search_filter')"
                    title="general.search"
                ></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      :model.sync="filters.start_date"
                      name="start_time"
                      title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      :model.sync="filters.end_date"
                      name="end_date"
                      title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="onHitBottom(null)">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                          @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <dashboard-box :title="$t('invoice.invoice_list')">
        <template v-slot:preview>

          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="formattedItems"
              :no-border-collapse="true"
              :outlined="false"
              :striped="false"
              tableVariant="''"
              @hitBottom="onHitBottom"
              @rowClicked="onRowClicked"
              :infiniteId="infiniteId"
              :sortBy.sync="order" 
              :sortDesc.sync="sort"
          ></data-table>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import { HANDLE_INFINITE_SCROLL, LOADING} from "@/core/services/store/REST.module";

import {FILTER_LOGO_ERROR as FILTER_LOGO, SET_FILTER_LOGO_ERROR as SET_FILTER_LOGO} from "@/core/services/store/invoice/incoming_invoice.module";
import {INVOICE_ERROR_INDEX_END_DATE_STORAGE_NAME, INVOICE_ERROR_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT, MOMENT_SYSTEM_TIME_FORMAT
} from "@/core/config/constant";
import draggable from 'vuedraggable'
import * as _ from "lodash";

export default {
  name: "InvoiceLogoIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    DateFilter,
    draggable
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_LOGO
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    formattedItems() {
      if (!this.invoices || this.invoices.data === null || this.invoices.data === undefined) return [];
      let temp = [];
      this.invoices.data.forEach(tempCurrent => {
        temp.push({
          'id': tempCurrent.id,
          'logicalref': tempCurrent.logicalref,
          'sendertitle': tempCurrent.sendertitle,
          'date': moment(tempCurrent.date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) + " " + moment(tempCurrent.time, LARAVEL_TIME_FORMAT).format(MOMENT_SYSTEM_TIME_FORMAT),
          'docdate': moment(tempCurrent.docdate, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'docnr': tempCurrent.docnr,
          'trcodename': tempCurrent.trcodename,
          'docexplain': tempCurrent.transfer_error
        })
      })
      return temp;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      return [
        {
          'key': 'id',
          'label': "ID",
          'sortable': true,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'sendertitle',
          'label': this.$t('invoice.company'),
          'sortable': true,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docnr',
          'label': this.$t('invoice.invoice_number'),
          'sortable': true,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docdate',
          'label': this.$t('invoice.invoice_date'),
          'sortable': true,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'date',
          'label': this.$t('invoice.action_date'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'trcodename',
          'label': this.$t('invoice.invoice_type'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'docexplain',
          'label': this.$t('invoice.description'),
          'sortable': false,
          'sort': false,
          'class': "text-center border border-top-0 border-left-0",
          'tdClass': "cursor-pointer",
        }
      ];
    }
  },
  data() {
    return {
      // Filters & pagination
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      search: null,
      paginate: [],
      page: 1,
      perPage: 25,
      infiniteId:1,
      sort: "desc",
      order: "id",
      startDate: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      invoices: {data: []},
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      invoiceListUrl: 'api/logo-records/invoices'
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER_LOGO,
    }),
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onHitBottom($state = null) {
      let self = this;
      if (self.isUserGranted('Waybill', ['viewAny'])) {
        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'order', this.order);
        this.$set(filters, 'sort', this.sort);
        this.$set(filters, 'is_error', 1);

        self.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.invoiceListUrl,
          filters: filters,
          '$state': $state,
        }).then(result => {
          if (result.status) {
            self.invoices = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        })
      }
    },
    filterResult() {
      localStorage.setItem(INVOICE_ERROR_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(INVOICE_ERROR_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        search: null,
        sort: "",
        order: "",
      })
    },
    onRowClicked(clickedItem, index, event) {
      if (this.isUserGranted('Invoice', ['view', 'viewAny'])) {
        this.$router.push({name: 'invoice.details', params: {id: clickedItem.logicalref, type: 2}});
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("invoice.invoices")}
    ]);

    if (this.isUserGranted('Invoice', ['viewAny'])) {

      if (!this.filter) {
        this.resetFilters();
      }
      else {
        this.currentDepoType = this.filter.current_depo_type;
      }
      this.filters.end_date = localStorage.hasOwnProperty(INVOICE_ERROR_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_ERROR_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(INVOICE_ERROR_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_ERROR_INDEX_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
      setTimeout(this.onHitBottom, 1000);
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
